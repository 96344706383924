<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <b-form-group
                class="form-group-el-select"
                label="Перевозчик"
              >
                <el-select-clearable
                  v-model="filter.id"
                  class="form-control"
                  name="contractor"
                  placeholder="Начните вводить название компании перевозчика"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersAllContractorsList"
                  :loading="loading"
                  @input="updateFilter('id')"
                  @clear="customersAllContractorsList"
                >
                  <el-option
                    v-for="item in contractors"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Рейтинг
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          class="table-rating"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="rating"
            slot-scope="props"
          >
            <span
              v-if="props.row.rating && props.row.rating.ratingIndexValues && props.row.rating.ratingIndexValues.length > 0"
              class="table-rating-bar"
              :style="`max-width: ${props.row.rating.rating}%;`"
            >
              <span
                v-for="(param, index) in props.row.rating.ratingIndexValues"
                v-b-tooltip.hover.bottom="param.index.ratingIndex.name"
                :class="`table-rating-bar__part_${index}`"
                class="table-rating-bar__part"
                :style="`width: ${Math.round((param.rating * param.index.koeff / props.row.totalKoeff * 100)) / 100}%;`"
              >
                <span v-if="param.index.koeff">{{ Math.round((param.rating * param.index.koeff / props.row.totalKoeff * 100)) / 100 }}</span>
                <span v-else>0</span>
              </span>
            </span>
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ElSelectClearable from '../../../components/ElSelectClearable.vue';
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../../components/mixins/notifications';
import {
  customersAllContractorsList,
  customerRatingTop,
} from '../../../services/api';
import moment from 'moment';
import tz from 'moment-timezone';
import {arrayStringToNumber} from '@/components/mixins/helpers';
import {queryToUrl, URLToQuery} from '@/services/http';

Vue.use(ServerTable);

export default {
  name: 'CustomerFlights',
  components: {
    ServerTable,
    Event,
    ElSelectClearable,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      contractors: [],
      filter: {
        id: [],
        page: 1,
        limit: 10,
      },
      loading: false,
      columns: [
        'contractor.name',
        'rating',
        'rating.rating',
      ],
      options: {
        customFilters: [
          'id',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customerRatingTop(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          data.items.forEach((item) => {
            if (item.rating && item.rating.ratingIndexValues) {
              item.totalKoeff = item.rating.ratingIndexValues.reduce(function(accumulator, currentValue) {
                if (currentValue.index.koeff > 0) {
                  return accumulator + currentValue.index.koeff;
                } else {
                  return accumulator;
                }
              }, 0);
              item.rating.ratingIndexValues.sort((a, b) => b.id - a.id);
            }
          });
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'contractor.name': 'Перевозчик',
          'rating': 'Рейтинг',
          'rating.rating': 'Итого',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 10,
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  mounted() {
    this.customersAllContractorsList();
    const queries = this.$router.currentRoute.query;
    /**
     * Если в URL есть параметр 'limit'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    /**
     * Если в URL есть параметр 'page'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  created() {
    this.initRouteParams();
  },
  methods: {
    formatDatetimeWithOriginalTimezone(date) {
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return date ? moment(date, 'YYYY-MM-DDTHH:mm').tz(clientTimezone).format('DD.MM.YYYY HH:mm') : '';
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateDateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] ? moment(this.filter[field]).format('YYYY-MM-DDTHH:mm') : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    async customersAllContractorsList(name) {
      this.loading = true;
      const params = {
        valid: true,
        top: 10,
      };
      if (name) {
        params.name = name;
      }
      const response = await customersAllContractorsList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.contractors = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.contractor.name,
          };
        });
      }
      this.loading = false;
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.id.length) {
        res.id = this.filter.id;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      let params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('id')) {
          params.id = arrayStringToNumber(params.id);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
      } else {
        params = this.$store.getters.getFilterParams(this.$options.name);
        if (!params) {
          params = {};
        }
        params.date_from = moment().format('YYYY-MM-DDT00:00:00.000Z');
        params.date_to = moment().format('YYYY-MM-DDT00:00:00.000Z');
        params.date_from = moment(params.date_from).add(8, 'hours').format();
        params.date_to = moment(params.date_to).add(32, 'hours').format();
        Object.assign(this.filter, params);
      }
    },
  },
};
</script>

<style lang="scss">
  .table-rating {

    thead {

      th {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          width: 75%;
        }
        &:nth-child(3) {
          width: 5%;
        }
      }
    }

    &-bar {
      display: flex;

      &__part {
        min-width: 30px;
        font-weight: bold;
        text-align: center;
        color: #f0f0f0;

        &_0 {background-color: #20a8d8;}
        &_1 {background-color: #6610f2;}
        &_2 {background-color: #6f42c1;}
        &_3 {background-color: #e83e8c;}
        &_4 {background-color: #f86c6b;}
        &_5 {background-color: #f8cb00;}
        &_6 {background-color: #ffc107;}
        &_7 {background-color: #4dbd74;}
        &_8 {background-color: #20c997;}
        &_9 {background-color: #17a2b8;}
        &_10 {background-color: #73818f;}
        &_11 {background-color: #2f353a;}
        &_12 {background-color: #63c2de;}
        &_13 {background-color: #c8ced3;}
      }
    }
  }
</style>
